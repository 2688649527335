<template>
  <div id="app" :style="{ background: pattern }">
    <div class="main-content">
      <div class="logos">
        <img
          alt="Logo Pontue - Redação Inteligente"
          :src="require(`@/assets/${logoPontue.src}`)"
          :style="{
            width: `${logoPontue.width}px`,
            height: `${logoPontue.height}px`,
          }"
        />
        <div class="plus">+</div>
        <img
          alt="Logo Parceiro Pontue"
          :src="require(`@/assets/${logoParceiro.src}`)"
          :style="{
            width: `${logoParceiro.width}px`,
            height: `${logoParceiro.height}px`,
          }"
        />
      </div>
      <div class="la-ball-atom la-2x" v-if="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div v-if="loading" class="message loading" v-html="loadingMessage"></div>
      <div v-else class="message error" v-html="errorMessage"></div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
// import store from '@/store'

export default {
  name: "app",
  methods: {
    getParams() {
      let query = window.location.search.substring(1);
      query = query.split("&");
      let params = {};
      query.forEach((el) => {
        let p = el.split("=");
        params[p[0]] = decodeURIComponent(p[1]);
      });
      return params;
    },
    loginUser() {
      api(process.env.VUE_APP_API_URL)
        .post(process.env.VUE_APP_API_PATH, this.getParams())
        .then((response) => {
          this.response = response.data;
          // store.commit('login', this.response)
          window.location.href = `${this.appUrl}/?${this.buildQuery()}`;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    redirectToApp() {
      window.location.href = this.appUrl;
    },
    buildQuery() {
      let obj = {
        access_token: this.response.access_token,
        user_id: this.response.user.id,
        user_email: this.response.user.email,
        role: this.response.role,
        user_name: this.response.user.nome,
        crisp: process.env.VUE_APP_SHOW_CRISP,
      };

      if (this.response.escola) {
        obj.escola_id = this.response.escola.id;
        obj.is_sas = this.response.escola.is_sas;
      }

      if (this.response.aluno) {
        (obj.aluno_id = this.response.aluno.id),
          (obj.aluno_turma_id = this.response.aluno.turma_id);
      }

      if (this.response.ssoConfigName) {
        obj.ssoConfigName = this.response.ssoConfigName;
      }

      if (this.response.ssoAccessToken) {
        obj.ssoAccessToken = this.response.ssoAccessToken;
      }

      return this.serializeObjasURL(obj);
    },
    serializeObjasURL(obj) {
      return Object.keys(obj)
        .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
        .join("&");
    },
  },
  data() {
    return {
      pattern: process.env.VUE_APP_BACKGROUND_PATTERN,
      errorMessage: process.env.VUE_APP_ERROR_MESSAGE,
      loadingMessage: process.env.VUE_APP_LOADING_MESSAGE,
      logoParceiro: {
        src: process.env.VUE_APP_LOGO_PARCEIRO,
        width: process.env.VUE_APP_LOGO_PARCEIRO_WIDTH,
        height: process.env.VUE_APP_LOGO_PARCEIRO_HEIGHT,
      },
      logoPontue: {
        src: process.env.VUE_APP_LOGO_PONTUE,
        width: process.env.VUE_APP_LOGO_PONTUE_WIDTH,
        height: process.env.VUE_APP_LOGO_PONTUE_HEIGHT,
      },
      loading: true,
      loadIframe: false,
      appUrl: process.env.VUE_APP_APP_URL,
      response: null,
    };
  },
  created() {
    // if (!store.getters.getExpiresAt || !store.getters.getToken) {
    //   this.loginUser()
    // } else if (new Date(store.getters.getExpiresAt) < new Date()) {
    //   this.loginUser()
    // } else {
    // setTimeout(
    //   this.redirectToApp
    // , 1000)
    this.loginUser();
    // }
  },
};
</script>

<style>
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-family: "Plus Jakarta Sans", sans-serif;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  width: 100vw;
  height: 100vh;
  display: flex;
}
#app {
  display: flex;
  flex-grow: 1;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logos {
  display: flex;
}
.logos img {
  align-self: center;
}
.plus {
  display: flex;
  align-self: center;
  color: #fff;
  font-size: 4rem;
  margin: 0 4rem;
}
.la-ball-atom {
  margin-top: 4rem;
  align-self: center;
}
.message {
  color: #fff;
  font-size: 1.25rem;
  line-height: 2rem;
  text-align: center;
}
.message.loading {
  margin-top: 3rem;
}
.message.error {
  margin-top: 4rem;
}
iframe {
  height: 0;
  width: 0;
  opacity: 0;
  visibility: hidden;
}
@media screen and (max-width: 580px) {
  .logos {
    flex-direction: column;
  }
  .plus {
    margin: 2rem 0;
  }
  .message {
    margin: 4rem 1.25rem 0 1.25rem;
  }
}
.la-ball-atom,
.la-ball-atom > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.la-ball-atom {
  display: block;
  font-size: 0;
  color: #fff;
}
.la-ball-atom.la-dark {
  color: #333;
}
.la-ball-atom > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.la-ball-atom {
  width: 32px;
  height: 32px;
}
.la-ball-atom > div:nth-child(1) {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 60%;
  height: 60%;
  background: #fff;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: ball-atom-shrink 4.5s infinite linear;
  -moz-animation: ball-atom-shrink 4.5s infinite linear;
  -o-animation: ball-atom-shrink 4.5s infinite linear;
  animation: ball-atom-shrink 4.5s infinite linear;
}
.la-ball-atom > div:not(:nth-child(1)) {
  position: absolute;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: none;
  -webkit-animation: ball-atom-zindex 1.5s 0s infinite steps(2, end);
  -moz-animation: ball-atom-zindex 1.5s 0s infinite steps(2, end);
  -o-animation: ball-atom-zindex 1.5s 0s infinite steps(2, end);
  animation: ball-atom-zindex 1.5s 0s infinite steps(2, end);
}
.la-ball-atom > div:not(:nth-child(1)):before {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-left: -5px;
  content: "";
  background: currentColor;
  border-radius: 50%;
  opacity: 0.75;
  -webkit-animation: ball-atom-position 1.5s 0s infinite ease,
    ball-atom-size 1.5s 0s infinite ease;
  -moz-animation: ball-atom-position 1.5s 0s infinite ease,
    ball-atom-size 1.5s 0s infinite ease;
  -o-animation: ball-atom-position 1.5s 0s infinite ease,
    ball-atom-size 1.5s 0s infinite ease;
  animation: ball-atom-position 1.5s 0s infinite ease,
    ball-atom-size 1.5s 0s infinite ease;
}
.la-ball-atom > div:nth-child(2) {
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.la-ball-atom > div:nth-child(2):before {
  -webkit-animation-delay: 0s, -1.125s;
  -moz-animation-delay: 0s, -1.125s;
  -o-animation-delay: 0s, -1.125s;
  animation-delay: 0s, -1.125s;
}
.la-ball-atom > div:nth-child(3) {
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.25s;
  -moz-animation-delay: -0.25s;
  -o-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.la-ball-atom > div:nth-child(3):before {
  -webkit-animation-delay: -1s, -0.75s;
  -moz-animation-delay: -1s, -0.75s;
  -o-animation-delay: -1s, -0.75s;
  animation-delay: -1s, -0.75s;
}
.la-ball-atom > div:nth-child(4) {
  -webkit-transform: rotate(240deg);
  -moz-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  -o-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.la-ball-atom > div:nth-child(4):before {
  -webkit-animation-delay: -0.5s, -0.125s;
  -moz-animation-delay: -0.5s, -0.125s;
  -o-animation-delay: -0.5s, -0.125s;
  animation-delay: -0.5s, -0.125s;
}
.la-ball-atom.la-sm {
  width: 16px;
  height: 16px;
}
.la-ball-atom.la-sm > div:not(:nth-child(1)):before {
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
}
.la-ball-atom.la-2x {
  width: 64px;
  height: 64px;
}
.la-ball-atom.la-2x > div:not(:nth-child(1)):before {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
}
.la-ball-atom.la-3x {
  width: 96px;
  height: 96px;
}
.la-ball-atom.la-3x > div:not(:nth-child(1)):before {
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
}
@-webkit-keyframes ball-atom-position {
  50% {
    top: 100%;
    left: 100%;
  }
}
@-moz-keyframes ball-atom-position {
  50% {
    top: 100%;
    left: 100%;
  }
}
@-o-keyframes ball-atom-position {
  50% {
    top: 100%;
    left: 100%;
  }
}
@keyframes ball-atom-position {
  50% {
    top: 100%;
    left: 100%;
  }
}
@-webkit-keyframes ball-atom-size {
  50% {
    -webkit-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
}
@-moz-keyframes ball-atom-size {
  50% {
    -moz-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
}
@-o-keyframes ball-atom-size {
  50% {
    -o-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
}
@keyframes ball-atom-size {
  50% {
    -webkit-transform: scale(0.5, 0.5);
    -moz-transform: scale(0.5, 0.5);
    -o-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
}
@-webkit-keyframes ball-atom-zindex {
  50% {
    z-index: 10;
  }
}
@-moz-keyframes ball-atom-zindex {
  50% {
    z-index: 10;
  }
}
@-o-keyframes ball-atom-zindex {
  50% {
    z-index: 10;
  }
}
@keyframes ball-atom-zindex {
  50% {
    z-index: 10;
  }
}
@-webkit-keyframes ball-atom-shrink {
  50% {
    -webkit-transform: translate(-50%, -50%) scale(0.8, 0.8);
    transform: translate(-50%, -50%) scale(0.8, 0.8);
  }
}
@-moz-keyframes ball-atom-shrink {
  50% {
    -moz-transform: translate(-50%, -50%) scale(0.8, 0.8);
    transform: translate(-50%, -50%) scale(0.8, 0.8);
  }
}
@-o-keyframes ball-atom-shrink {
  50% {
    -o-transform: translate(-50%, -50%) scale(0.8, 0.8);
    transform: translate(-50%, -50%) scale(0.8, 0.8);
  }
}
@keyframes ball-atom-shrink {
  50% {
    -webkit-transform: translate(-50%, -50%) scale(0.8, 0.8);
    -moz-transform: translate(-50%, -50%) scale(0.8, 0.8);
    -o-transform: translate(-50%, -50%) scale(0.8, 0.8);
    transform: translate(-50%, -50%) scale(0.8, 0.8);
  }
}
</style>
