var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({ background: _vm.pattern }),attrs:{"id":"app"}},[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"logos"},[_c('img',{style:({
          width: `${_vm.logoPontue.width}px`,
          height: `${_vm.logoPontue.height}px`,
        }),attrs:{"alt":"Logo Pontue - Redação Inteligente","src":require(`@/assets/${_vm.logoPontue.src}`)}}),_c('div',{staticClass:"plus"},[_vm._v("+")]),_c('img',{style:({
          width: `${_vm.logoParceiro.width}px`,
          height: `${_vm.logoParceiro.height}px`,
        }),attrs:{"alt":"Logo Parceiro Pontue","src":require(`@/assets/${_vm.logoParceiro.src}`)}})]),(_vm.loading)?_c('div',{staticClass:"la-ball-atom la-2x"},[_c('div'),_c('div'),_c('div'),_c('div')]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"message loading",domProps:{"innerHTML":_vm._s(_vm.loadingMessage)}}):_c('div',{staticClass:"message error",domProps:{"innerHTML":_vm._s(_vm.errorMessage)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }