import axios from 'axios'

export default (baseURL = 'http://127.0.0.1:8000') => {
  let api = axios.create({
    baseURL: baseURL,
    withCredentials: false,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },
    validateStatus: (status) => status < 500
  })

  api.interceptors.response.use(function (response) {
    if (response.status === 401) {
      return Promise.reject(new Error('Permissão negada'))
    }

    return response
  }, error => {
    let message = ''
    if (!error.status) {
      message = 'Erro ao conectar no servidor'
    }

    return Promise.reject(new Error(message))
  })

  return api
}
